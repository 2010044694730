<template>
    <div style="width: 100%;min-height:100vh;">
        <div style="width: 100%;background: #FFFFFF">
            <div style="width: 1500px;height: 100px;display: flex;align-items: center;justify-content: space-between;margin: 0 auto;background: #FFFFFF" >
                <div style="display: flex;align-items: center;align-items: center">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png" style="height: 46px;width: auto;margin: 0 10px;"/>
                    <div style="color: #031F88;font-size: 30px;font-weight: bold"> | 帮助中心</div>

                    <div style="width: 600px;background: #FFFFFF"></div>
                </div>
                <div style="display: flex;align-items: center;">
<!--                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/notice.png" style="height: 22px;width: auto"/>-->

<!--                    <img :src="user.headIco" style="height: 30px;width: 30px;margin: 0 10px 0 40px;border-radius: 50%;"/>-->

<!--                    <span style="margin-right: 10px;font-size: 18px;">{{user.nickName}}</span>-->

<!--                    <i class="el-icon-caret-bottom"></i>-->

                </div>
            </div>
        </div>
        <div style="width: 1500px;display: flex;margin: 0 auto;padding: 40px 0;">
            <div style="width: 1008px;margin: 0 auto;box-shadow:10px 0px 8px -15px; rgba(0,0,0,0.2);">
                <el-autocomplete
                    style="width: 1008px;"
                    v-model="searchfor"
                    :fetch-suggestions="get_help_center"
                    placeholder="请输入关键词搜索"
                    @select="handleSelect"
                >
                    <template slot="default" slot-scope="{ item }">
                        <div>{{ item.title }}</div>
                    </template>
                    <template slot="append"><el-button type="primary" @click="get_help_center()" style="background: #2970FF;color: #FFFFFF;border-radius: 0px 5px 5px 0px;height: 40px"><i class="el-icon-search"></i></el-button></template>
                </el-autocomplete>
            </div>
        </div>
        <div style="display: flex;justify-content: center;">
            <div class="rolling" style="width: 420px;max-height: 690px;overflow-y: auto; background: white;">
                <div v-for="(it,ind) in helpType" :key="ind" style="width: 150px;height: 21px;padding-top: 20px;margin: 0 auto">
                    <div style="height: 21px;font-size: 18px;cursor: pointer;text-align: center;margin-bottom: 10px;font-weight: bold">{{ it.label }}</div>
                </div>
                <div style="width: 100%;margin-top: 20px">
                    <el-collapse v-model="activeName" accordion >
                        <!--   一级   -->
                        <el-collapse-item v-for="(item, index) in helpcenter" :key="index" :disabled="!item.subList || item.subList.length === 0" :name="item.title" :collapse="item.title === activeName">
                            <template slot="title" style="width: 90%;">
                                <div style="width: 100%;display: flex;overflow: hidden">
                                    <div style="padding-left: 30px">
                                        <i v-if="item.title === activeName" class="el-icon-s-flag" style="font-size: 20px;color: #409eff"></i>
                                        <div v-else style="width: 10px;height: 10px;border-radius: 50%;margin-top: 20px;background: #BDBFC1"></div>
                                    </div>
                                    <div :style="{ color: item.title === activeName ? '#409eff' : '' }" style="font-weight: 400;padding-left: 10px;">{{ item.title }}</div>
                                </div>
                            </template>
                            <!--  二级  -->
                            <div v-for="(i, k) in item.subList" :key="k" style="overflow: hidden;">
                                <div style="display: flex;align-items: center;margin-top: 15px;overflow: hidden;height: auto">
                                    <div style="padding-left: 30px;">
                                        <div :style="i.title == selectedLabel ? 'background:#2970FF':'background:#BDBFC1'"  style="width: 10px;height: 10px;;border-radius: 50%;"></div>
                                    </div>
                                    <div @click="add_list(i.title, i.id)" :style="i.title == selectedLabel ? 'color:#2970FF':''" style="font-size: 15px; cursor: pointer;padding-left: 10px;">
                                        {{ i.title }}
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <div style="width: 1070px;height: 690px;background: #FFFFFF;margin-left: 10px;">
                 <div style="font-weight: 400;color: #444444;font-size: 30px;margin: 26px 30px;">{{helpinfo.title}}</div>
                 <div v-if="helpinfo.title"><el-divider></el-divider></div>
                 <div class="bold" style="font-size: 16px;color: #444444;margin: 0 30px;overflow: hidden;overflow-y: scroll;max-height: 570px;" v-html="helpinfo.content"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data () {
        return {
            searchfor:'',
            helpType: [
                { id: 0, label: "用户帮助",},
            ],
            label:0,
            glide_index:0,
            selected:1,
            selectedLabel:'',
            helpcenter:[],
            activeName: "",
            helpinfo:[],
            helpCenter: []

        }
    },
    mounted:function(){
        this.getHelpCenter();
        if(this.$route.params.id > 0){
            this.getHelpInfo(this.$route.params.id);
        }

    },
    methods:{
        getHelpCenter(){
            var that = this;
            that.newApi.getHelpCenter({
                type:2,
            }).then((res)=>{
                if (res.isSuccess == 1) {
                    that.helpcenter = res.data
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        add_list(title,id){
            this.selectedLabel = title
            this.getHelpInfo(id);
        },
        getHelpInfo(id){
            var that = this;
            that.newApi.getHelpInfo({
                id:id
            }).then((res)=>{
                if (res.isSuccess == 1) {
                    that.helpinfo = res.data
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        //  搜索
        get_help_center(queryString, cb) {
            if (queryString && queryString.trim() !== '') {
                var that = this;
                that.newApi.getHelpCenter({
                    like: queryString,
                    type: 2,
                }).then((res) => {
                    if (res.isSuccess === 1) {
                        that.help_center = res.data;
                        cb(that.help_center);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                // 清空下拉框内容
                cb([]);
            }
        },
        handleSelect(item) {
            const id = item.id;
            this.getHelpInfo(id);
        }

    }
}
</script>
<style scoped>
    .selected {
        color: #409eff;
    }

    .subItem {
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        min-height: 40px;
        line-height: 40px;
        margin-left: 10px;
    }

    .subItem.selected {
        background-color: #2970FF;
        color: #FFFFFF;
    }

  /deep/ .el-collapse-item__header {
       font-size: 16px;
   }
 /deep/ .bold .span{
      font-size: 16px !important;

  }
 .bold>>>img {
     width: 100%;
 }

 .el-input__inner {
     font-weight: bold;
 }

  .bold {
      font-size: 16px !important;
  }
    .rolling::-webkit-scrollbar {
        display: none;
    }
    .bold::-webkit-scrollbar {
        display: none;
    }
</style>
